import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import header from "./AboutDEASContent/header_about_deas.png";
import "../App.css";
import { withRouter } from "react-router-dom";
import React from "react";
import ScrollToAnchor from "./ScrollToAnchor";
import revit from "./ControlRoomImages/Revit.svg";
import civil3d from "./ControlRoomImages/civil3d.svg";
import tooldeveloper from "./GuidesImages/tooldeveloper.svg";
import bram from "./HomePageContent/bramtegels.png";
import andrew from "./HomePageContent/andrewvictory.png";
import matthe from "./AboutDEASContent/matthe.png";
import pablo from "./RevitConnectorContent/pablo.png";
import ovidiu from "./AboutDEASContent/ovidiu.png";
import constantin from "./AboutDEASContent/constantin.png";
import camelia from "./AboutDEASContent/camelia.png";
import team from "./AboutDEASContent/team.svg";
import install from "./AboutDEASContent/install.svg";
import errors from "./AboutDEASContent/errors.svg";
import notes from "./AboutDEASContent/notes.svg";

const AboutDEAS = (props) => {
  const history = { props }.props.history;
  ScrollToAnchor();

  return (
    <div>
      <Grid paddingRight={"20px"}>
        <Grid container item xs={500}>
          <Grid container item xs={12}>
            <Grid item xs={12} style={{ backgroundImage: `url(${header})`, backgroundSize: "cover", height: "180px" }}>
              <Grid paddingTop={"100px"} paddingLeft={"20px"}>
                <p style={{ color: "#e4610f", fontSize: "25px", fontWeight: "500" }}>About DEAS</p>
              </Grid>
            </Grid>

            <Grid item xs={4} paddingRight="10px" paddingTop="20px">
              <p className="standard-text" style={{ alignContent: "flex-end" }}>
                DEAS drives <span style={{ color: "#e4610f", fontStyle: "italic" }}>efficiency</span> and{" "}
                <span style={{ color: "#e4610f", fontStyle: "italic" }}>quality </span>
                in Arcadis D&E work by offering a platform for improving communication, globally scaling standardization
                and automation, and providing insight in adoption and usage of software and tooling.
              </p>
            </Grid>

            <Grid item xs={4} paddingLeft={"10px"}></Grid>

            <Grid item xs={0.25} borderRight={"1px solid #d0d0d0"} />
            <Grid item xs={0.25} />

            <Grid item xs={3.5} container justifyContent={"center"} paddingTop="20px">
              <Grid item xs={8} paddingBottom="10px" paddingTop="15px">
                <Button
                  variant="contained"
                  fullWidth
                  className="mainhome-button"
                  onClick={() => (window.location.href = "#team")}
                >
                  <Grid item xs={2} paddingTop={"5px"}>
                    <img src={team} className="svg" alt="" />
                  </Grid>
                  <Grid item xs={6} display="flex" direction="row" alignContent="left">
                    Team
                  </Grid>
                </Button>
              </Grid>

              <Grid item xs={8} paddingBottom="10px">
                <Button
                  variant="contained"
                  fullWidth
                  className="mainhome-button"
                  onClick={() => {
                    window.open(
                      "https://appstore.poweredby.arcadis.com/?software=8044a8e0-6a37-4bb3-b339-2aa4a46e2505",
                      "_blank",
                      "noopener"
                    );
                  }}
                >
                  <Grid item xs={2} paddingTop={"5px"}>
                    <img src={install} className="svg" alt="" />
                  </Grid>
                  <Grid item xs={6} display="flex" direction="row" alignContent="left">
                    Install DEAS
                  </Grid>
                </Button>
              </Grid>

              <Grid item xs={8} paddingBottom="10px">
                <Button
                  variant="contained"
                  fullWidth
                  className="mainhome-button"
                  onClick={() => (window.location.href = "#issues-errors")}
                >
                  <Grid item xs={2} paddingTop={"5px"}>
                    <img src={errors} className="svg" alt="" />
                  </Grid>
                  <Grid item xs={6} display="flex" direction="row" alignContent="left">
                    Issues / errors
                  </Grid>
                </Button>
              </Grid>

              <Grid item xs={8}>
                <Button
                  variant="contained"
                  fullWidth
                  className="mainhome-button"
                  onClick={() => {
                    window.open(
                      "https://arcadiso365.sharepoint.com/teams/DEAS-DesignEngineeringAutomationSystem/SitePages/DEAS-ReleaseNotes.aspx",
                      "_blank",
                      "noopener"
                    );
                  }}
                >
                  <Grid item xs={2} paddingTop={"5px"}>
                    <img src={notes} className="svg" alt="" />
                  </Grid>
                  <Grid item xs={6} display="flex" direction="row" alignContent="left">
                    Release notes
                  </Grid>
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid id="team" style={{ paddingBottom: "40px" }} />
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: "500",
                  color: "#e4610f",
                  height: "80px",
                  background: "#f0f0f0",
                  paddingTop: "25px",
                }}
              >
                Team
              </p>
            </Grid>

            <Grid item xs={12} container borderBottom={"1px solid #d0d0d0"} paddingBottom={"20px"}>
              <Grid item xs={4} container justifyContent="center">
                <p style={{ fontSize: "16px", fontWeight: "600" }}>Product Owner</p>
                <Grid container justifyContent="center">
                  <Grid paddingRight={"10px"}>
                    <img src={bram} width="50px" height="50px" style={{ borderRadius: "50%" }} alt="" />
                  </Grid>
                  <Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "600", textAlign: "start" }} paddingTop={"10px"}>
                      Tegels, Bram
                    </Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "500", textAlign: "start" }}>Senior Consultant</Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4} container justifyContent="center">
                <p style={{ fontSize: "16px", fontWeight: "600" }}>Product Manager</p>
                <Grid container justifyContent="center">
                  <Grid paddingRight={"10px"}>
                    <img src={matthe} width="50px" height="50px" style={{ borderRadius: "50%" }} alt="" />
                  </Grid>
                  <Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "600", textAlign: "start" }} paddingTop={"10px"}>
                      van Baalen, Matthe{" "}
                    </Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "500", textAlign: "start" }}>
                      Global Leader Design & Engineering
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4} container justifyContent="center">
                <p style={{ fontSize: "16px", fontWeight: "600" }}>Implementation</p>
                <Grid container justifyContent="center">
                  <Grid paddingRight={"10px"}>
                    <img src={andrew} width="50px" height="50px" style={{ borderRadius: "50%" }} alt="" />
                  </Grid>
                  <Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "600", textAlign: "start" }} paddingTop={"10px"}>
                      Victory, Andrew
                    </Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "500", textAlign: "start" }}>
                      Global Digital Transformation Lead - D&E
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} container paddingTop={"20px"} paddingBottom={"20px"}>
              <Grid item xs={3} container justifyContent="center">
                <p style={{ fontSize: "14px", fontWeight: "600" }}>Developer</p>
                <Grid container justifyContent="center">
                  <Grid paddingRight={"10px"}>
                    <img src={pablo} width="50px" height="50px" style={{ borderRadius: "50%" }} alt="" />
                  </Grid>
                  <Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "600", textAlign: "start" }} paddingTop={"10px"}>
                      Alvares, Pablo
                    </Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "500", textAlign: "start" }}>Design Manager</Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={3} container justifyContent="center">
                <p style={{ fontSize: "14px", fontWeight: "600" }}>Developer</p>
                <Grid container justifyContent="center">
                  <Grid paddingRight={"10px"}>
                    <img src={ovidiu} width="50px" height="50px" style={{ borderRadius: "50%" }} alt="" />
                  </Grid>
                  <Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "600", textAlign: "start" }} paddingTop={"10px"}>
                      Parasca, Ovidiu
                    </Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "500", textAlign: "start" }}>
                      Full Stack Developer
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={3} container justifyContent="center">
                <p style={{ fontSize: "14px", fontWeight: "600" }}>Developer</p>
                <Grid container justifyContent="center">
                  <Grid paddingRight={"10px"}>
                    <img src={constantin} width="50px" height="50px" style={{ borderRadius: "50%" }} alt="" />
                  </Grid>
                  <Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "600", textAlign: "start" }} paddingTop={"10px"}>
                      Ciortan, Constantin
                    </Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "500", textAlign: "start" }}>Software Developer</Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={3} container justifyContent="center">
                <p style={{ fontSize: "14px", fontWeight: "600" }}>Data Analyst</p>
                <Grid container justifyContent="center">
                  <Grid paddingRight={"10px"}>
                    <img src={camelia} width="50px" height="50px" style={{ borderRadius: "50%" }} alt="" />
                  </Grid>
                  <Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "600", textAlign: "start" }} paddingTop={"10px"}>
                      Cacina, Camelia
                    </Grid>
                    <Grid style={{ fontSize: "12px", fontWeight: "500", textAlign: "start" }}>Data analyst</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} id="issues-errors">
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: "500",
                  color: "#e4610f",
                  height: "80px",
                  background: "#f0f0f0",
                  paddingTop: "25px",
                }}
              >
                Issues / errors
              </p>
            </Grid>

            <Grid item xs={8} paddingBottom={"50px"}>
              <p className="standard-text" style={{ alignContent: "flex-end" }}>
                If you encounter any issues with DEAS please follow up according to the following guidelines:
              </p>

              <p className="standard-text" style={{ alignContent: "flex-end" }}>
                <span style={{ fontWeight: "600" }}>
                  Is the issue concerning the functionality of a tool provided in the software?<br></br>
                </span>
                Contact the owner of the tool (kit). The owners are shown in the guides for the respective
                software&apos;s.
              </p>

              <p className="standard-text" style={{ alignContent: "flex-end" }}>
                <span style={{ fontWeight: "600" }}>
                  Is the issue concerning installation of DEAS?<br></br>
                </span>
                Contact IT service desk via: <u>itservicedesk@arcadis.com.</u>
              </p>

              <p className="standard-text" style={{ alignContent: "flex-end" }}>
                <span style={{ fontWeight: "600" }}>
                  Is the issue concerning DEAS effecting a limited amount of colleagues?<br></br>
                </span>
                Contact IT service desk via: <u>itservicedesk@arcadis.com.</u>
              </p>

              <p className="standard-text" style={{ alignContent: "flex-end" }}>
                <span style={{ fontWeight: "600" }}>
                  Are you a developer and you have questions around your tool deployment or maintenance?<br></br>
                </span>
                Check the Tool developer guide. If this does not solve your issue, please contact the product owner of
                DEAS.
              </p>

              <p className="standard-text" style={{ alignContent: "flex-end" }}>
                <span style={{ fontWeight: "600" }}>
                  Is the issue around a broadly experienced defect, that hinders continuity of projects?<br></br>
                </span>
                Contact IT service desk via: <u>itservicedesk@arcadis.com</u>, but also cc the product owner of DEAS.
              </p>
            </Grid>

            <Grid item xs={0.25} borderRight={"1px solid #d0d0d0"} />
            <Grid item xs={0.25} />

            <Grid item xs={3.5} container justifyContent={"center"}>
              <Grid item xs={8} paddingBottom="10px" paddingTop="15px">
                <Button
                  variant="contained"
                  fullWidth
                  className="mainhome-button"
                  onClick={() => history.push("/Guides/RevitConnector")}
                >
                  <Grid item xs={2} paddingTop={"5px"}>
                    <img src={revit} className="svg_tool" alt="" />
                  </Grid>
                  <Grid item xs={6} display="flex" direction="row" alignContent="left">
                    Revit Guide
                  </Grid>
                </Button>
              </Grid>

              <Grid item xs={8} paddingBottom="10px">
                <Button
                  variant="contained"
                  fullWidth
                  className="mainhome-button"
                  onClick={() => history.push("/Guides/Civil3DConnector")}
                >
                  <Grid item xs={2} paddingTop={"5px"}>
                    <img src={civil3d} className="svg_tool" alt="" />
                  </Grid>
                  <Grid item xs={6} display="flex" direction="row" alignContent="left">
                    Civil 3D Guide
                  </Grid>
                </Button>
              </Grid>

              <Grid item xs={8} paddingBottom="150px">
                <Button
                  variant="contained"
                  fullWidth
                  className="mainhome-button"
                  onClick={() => history.push("/Guides/ToolDeveloper")}
                >
                  <Grid item xs={2} paddingTop={"5px"}>
                    <img src={tooldeveloper} className="svg" alt="" />
                  </Grid>
                  <Grid item xs={6} display="flex" direction="row" alignContent="left">
                    Tool developer Guide
                  </Grid>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid bgcolor={"#e4610f"}>
          <canvas id="myCanvas" height="30px" style={{ border: "1px solid #e4610f" }}></canvas>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(AboutDEAS);
